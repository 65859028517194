import React from 'react';
import PropTypes from 'prop-types';
import HomePage from '../components/Views/HomePage';
import { getSeoData, getVippedRowItems } from '../utils/helpers';
import Layout from '../components/Layout/Layout';

const App = ({ vippedRowItemsData, statusCode, seoData }) => (
  <HomePage {...{ vippedRowItemsData, statusCode, seoData }} />
);

App.getInitialProps = async ({ apolloClient, asPath, req }) => {
  const seoData = await getSeoData(apolloClient, asPath, req);
  const vippedRowItemsData = await getVippedRowItems(apolloClient, asPath, req);

  return {
    seoData,
    vippedRowItemsData
  };
};

App.propTypes = {
  vippedRowItemsData: PropTypes.object,
  statusCode: PropTypes.number,
  seoData: PropTypes.object
};

App.getLayout = page => <Layout isHomePage={true}>{page}</Layout>;

export default App;
